import React, { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink, useHistory } from "react-router-dom";
import "../styles/sidebar.css";
import axios from "axios";

const Sidebar = () => {
  const [users, setUsers] = useState([]);

  const history = useHistory();

  // useEffect(() => {
  //   async function users() {
  //     const res = await axios.get("/api/current/user");
  //     if (res.data.status === 200) {
  //       setUsers({
  //         username: res.data.users.username,
  //       });
  //     }
  //   }
  //   users();
  // }, []);

  const juridica = localStorage.getItem("tipo") === "1";

  const auth = localStorage.getItem("auth_name");
  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("auth_name");
    localStorage.removeItem("auth_token");
    history.push("/login");
  };
  return (
    <>
      <Menu>
        {auth ? (
          <ul className="navbar-ul">
            <li className="navbar-li">
              <div className="profile-line">
                <div className="profile-icon"></div>
                <aside id="drop-link">{users.username}</aside>
              </div>
            </li>
            <li className="navbar-li">
              <NavLink to="/" activeClassName="active" exact>
                Inicio
              </NavLink>
            </li>

            <li className="navbar-li">
              <NavLink to="/Tutoriales" activeClassName="active" exact>
                Tutoriales
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/About" activeClassName="active" exact>
                Conócenos
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/Contact" activeClassName="active" exact>
                Contacto
              </NavLink>
            </li>
            <hr />
            <li className="navbar-li">
              {juridica ? (
                <NavLink to="/user/profile/juridica" exact>
                  Perfil
                </NavLink>
              ) : (
                <NavLink to="/user/profile/fisica" exact>
                  Perfil
                </NavLink>
              )}
            </li>
            <li className="navbar-li">
              <NavLink to="/Login" className="link" onClick={handleSignOut}>
                Cerrar sesión
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="navbar-ul">
            <li className="navbar-li">
              <NavLink to="/" activeClassName="active" exact>
                Inicio
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/About" activeClassName="active" exact>
                Conócenos
              </NavLink>
            </li>
            <li className="navbar-li">
              <NavLink to="/Contact" activeClassName="active" exact>
                Contacto
              </NavLink>
            </li>
            <hr />
            <li className="navbar-li">
              <NavLink to="/Login" className="link" onClick={handleSignOut}>
                Inicia sesión
              </NavLink>
            </li>
          </ul>
        )}
      </Menu>
    </>
  );
};

export default Sidebar;
