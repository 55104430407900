import {React, useLayoutEffect} from "react"
import logo from '../assets/logo/logo.png'
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import image1 from '../assets/images/amico.png'
import Footer from '../components/footer';
import Whatsapp from '../components/whatsapp';
import Tutos from '../components/tutos';
import Contact from '../components/contact-form';

const Tutoriales = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      });
    return (
        <>
            <Sidebar />
            <Whatsapp />

            <section className="s1">
                <header>
                    <div className='container-home'>
                        <div>
                            <img className='logo' src={logo} alt="logo" />
                        </div>
                        <Navbar />
                    </div>
                    {/*<main>
                        <div className="left" id='left-tutos'>
                            <h1>Tutoriales Del CRM</h1>
                            <h2>
                                Aqui encontrara informacion sobre como usar el CRM y su correcto funcionamiento.
                            </h2>
                            <h3>CRM Software</h3>
                            
                        </div>
                        <div className="right">
                            <img src={image1} alt="image1" />
                        </div>
    </main>*/}
                </header>
            </section>

            <Tutos/>


            <Footer/>
        </>
    )
}

export default Tutoriales;