import React from "react";
import "../styles/form-register.css";
import swal from "sweetalert";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/logo/icon.ico";
import icon1 from "../assets/icons/icons-form/Group 2.svg";
import icon2 from "../assets/icons/icons-form/Group 3.svg";
import icon3 from "../assets/icons/icons-form/Group 4.svg";
import icon4 from "../assets/icons/icons-form/Group 5.svg";
import icon5 from "../assets/icons/icons-form/Group 6.svg";
import icon6 from "../assets/icons/icons-form/Group 7.svg";
import icon7 from "../assets/icons/icons-form/Group 8.svg";
import icon8 from "../assets/icons/icons-form/Group 9.svg";
import icon9 from "../assets/icons/icons-form/Group 10.svg";
import icon10 from "../assets/icons/icons-form/Group 11.svg";
import icon11 from "../assets/icons/icons-form/Group 12.svg";
import icon12 from "../assets/icons/icons-form/Path 19.svg";
import icon13 from "../assets/icons/icons-form/Path 27.svg";
import icon14 from "../assets/icons/icons-form/Path 30.svg";
import icon15 from "../assets/icons/icons-form/Path 43.svg";
import icon16 from "../assets/icons/icons-form/Path 44.svg";
import icon17 from "../assets/icons/icons-form/Path 44.svg";
import Switch from "./switch-button";

const Logineso = () => {
  const { id } = useParams();
  const user_id = id;

  const history = useHistory();
  const [registerInput, setRegister] = useState({
    Cif: "",
    Nombre_Fiscal: "",
    Nombre_Administrador: "",
    Dni: "",
    Domicilio_Fiscal: "",
    Telefono_Fijo: "",
    Telefono_Movil: "",
    Email: "",
    Tipo_Empresa: "",
    Numero_Usuarios: "",
    Pais: "",
    Provincia: "",
    Domicilio: "",
    Codigo_Postal: "",
    Ciudad: "",
    Subdominio: "",
    Forma_Pago: "",
    Nombre_Tarjeta: "",
    Numero_Tarjeta: "",
    CVV_Tarjeta: "",
    Caducidad_Suscripcion: "",
    tipo: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setRegister({ ...registerInput, [e.target.name]: e.target.value });
  };

  // const handleOption = (option) => {
  //     setOption('');
  // };

  // const handleImage = (files) => {
  //     setImagedata(files);
  // };

  // for (let i = 0; i < imagedata.length; i++)
  //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)

  const registerSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Cif", registerInput.Cif);
    data.append("Nombre_Fiscal", registerInput.Nombre_Fiscal);
    data.append("Domicilio_Fiscal", registerInput.Domicilio_Fiscal);
    data.append("Nombre_Administrador", registerInput.Nombre_Administrador);
    data.append("Dni", registerInput.Dni);
    data.append("Telefono_Fijo", registerInput.Telefono_Fijo);
    data.append("Telefono_Movil", registerInput.Telefono_Movil);
    data.append("Email", registerInput.Email);
    data.append("Tipo_Empresa", registerInput.Tipo_Empresa);
    data.append("Numero_Usuarios", registerInput.Numero_Usuarios);
    data.append("Pais", registerInput.Pais);
    data.append("Provincia", registerInput.Provincia);
    data.append("Domicilio", registerInput.Domicilio);
    data.append("Codigo_Postal", registerInput.Codigo_Postal);
    data.append("Ciudad", registerInput.Ciudad);
    data.append("Subdominio", registerInput.Subdominio);
    data.append("Forma_Pago", registerInput.Forma_Pago);
    data.append("Nombre_Tarjeta", registerInput.Nombre_Tarjeta);
    data.append("Numero_Tarjeta", registerInput.Numero_Tarjeta);
    data.append("CCV_Tarjeta", registerInput.CVV_Tarjeta);
    data.append("Caducidad_Suscripcion", registerInput.Caducidad_Suscripcion);
    data.append("tipo", registerInput.tipo);

    await axios.post(`/api/create/legal/user/${user_id}`, data).then((res) => {
      if (res.data.status === 200) {
        console.log(res.data);

        swal({
          title: "Buen trabajo !",
          text: "Se ha registrado como persona juridica !",
          icon: "success",
          button: "Continuar...",
        });
        setRegister({
          Cif: "",
          Nombre_Fiscal: "",
          Nombre_Administrador: "",
          Dni: "",
          Domicilio_Fiscal: "",
          Telefono_Fijo: "",
          Telefono_Movil: "",
          Email: "",
          Tipo_Empresa: "",
          Numero_Usuarios: "",
          Pais: "",
          Provincia: "",
          Domicilio: "",
          Codigo_Postal: "",
          Ciudad: "",
          Subdominio: "",
          Forma_Pago: "",
          Nombre_Tarjeta: "",
          Numero_Tarjeta: "",
          CVV_Tarjeta: "",
          Caducidad_Suscripcion: "",
        });
        localStorage.setItem("tipo", res.data.tipo);

        history.push("/user/profile/juridica");
      } else {
        console.log(res.data);
        setRegister({
          ...registerInput,
          error_list: res.data.validation_errors,
        });
      }
    });
  };

  const registerSubmites = async (e) => {
    e.preventDefault();

    const data = new FormData();

    data.append("Nombre", registerInput.Nombre);
    data.append("Dni", registerInput.Dni);
    data.append("Telefono_Movil", registerInput.Telefono_Movil);
    data.append("Email", registerInput.Email);
    data.append("Tipo_Empresa", registerInput.Tipo_Empresa);
    data.append("Numero_Usuarios", registerInput.Numero_Usuarios);
    data.append("Pais", registerInput.Pais);
    data.append("Provincia", registerInput.Provincia);
    data.append("Domicilio", registerInput.Domicilio);
    data.append("Codigo_Postal", registerInput.Codigo_Postal);
    data.append("Ciudad", registerInput.Ciudad);
    data.append("Subdominio", registerInput.Subdominio);
    data.append("Forma_Pago", registerInput.Forma_Pago);
    data.append("Nombre_Tarjeta", registerInput.Nombre_Tarjeta);
    data.append("Numero_Tarjeta", registerInput.Numero_Tarjeta);
    data.append("CCV_Tarjeta", registerInput.CVV_Tarjeta);
    data.append("Caducidad_Suscripcion", registerInput.Caducidad_Suscripcion);

    await axios
      .post(`/api/create/physical/user/${user_id}`, data)
      .then((res) => {
        if (res.data.status === 200) {
          swal({
            title: "Buen trabajo !",
            text: "Se ha registrado como persona juridica !",
            icon: "success",
            button: "Continuar...",
          });
          setRegister({
            Nombre: "",
            Dni: "",
            Telefono_Movil: "",
            Email: "",
            Tipo_Empresa: "",
            Numero_Usuarios: "",
            Pais: "",
            Provincia: "",
            Domicilio: "",
            Codigo_Postal: "",
            Ciudad: "",
            Subdominio: "",
            Forma_Pago: "",
            Nombre_Tarjeta: "",
            Numero_Tarjeta: "",
            CVV_Tarjeta: "",
            Caducidad_Suscripcion: "",
          });

          history.push("/user/profile/fisica");
        } else {
          setRegister({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
        }
      });
  };

  return (
    <>
      <section className="s-form">
        <div className="s-form-switch">
          <Switch />
        </div>
        <form
          onSubmit={registerSubmit}
          className="s-form-input"
          id="s-form-id-1"
        >
          <div className="form-reg">
            <div className="s-form-div">
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon7} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Cif"
                    id=""
                    className=""
                    onChange={handleInput}
                    value={registerInput.Cif}
                    placeholder="CIF"
                  />
                  <span className="text-danger">
                    {registerInput.error_list.Cif}
                  </span>
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon1} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Nombre_Fiscal"
                    onChange={handleInput}
                    value={registerInput.Nombre_Fiscal}
                    id=""
                    placeholder="Nombre Fiscal"
                  />
                     <span className="text-danger">
                    {registerInput.error_list.Nombre_Fiscal}
                  </span>
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon12} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Domicilio_Fiscal"
                    onChange={handleInput}
                    value={registerInput.Domicilio_Fiscal}
                    id=""
                    className=""
                    placeholder="Domicilio Fiscal"
                  />
                     <span className="text-danger">
                    {registerInput.error_list.Domicilio_Fiscal}
                  </span>
                </div>
                <div className="s-form-input-field" id="select-input">
                  <div className="s-form-img-container">
                    <img src={icon4} alt="" />
                  </div>
                  <select
                    class="form-select"
                    name="Tipo_Empresa"
                    onChange={handleInput}
                    value={registerInput.Tipo_Empresa}
                  >
                    <option selected>Tipo Empresa</option>
                    <option>Correduria</option>
                    <option>Agente Exclusivo</option>
                  </select>
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon6} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Nombre_Administrador"
                    
                    onChange={handleInput}
                    value={registerInput.Nombre_Administrador}
                    id=""
                    className=""
                    placeholder="Nombre Administrador"
                  />
                     <span className="text-danger">
                    {registerInput.error_list.Nombre_Administrador}
                  </span>
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon14} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Dni"
                    onChange={handleInput}
                    value={registerInput.Dni}
                    id=""
                    placeholder="DNI/NIE"
                  />
                  <span className="text-danger">
                    {registerInput.error_list.Dni}
                  </span>
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon15} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Telefono_Fijo"
                    onChange={handleInput}
                    value={registerInput.Telefono_Fijo}
                    id=""
                    className=""
                    placeholder="Telefono Fijo"
                  />
                     <span className="text-danger">
                    {registerInput.error_list.Telefono_Fijo}
                  </span>
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon16} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Telefono_Movil"
                    onChange={handleInput}
                    value={registerInput.Telefono_Movil}
                    id=""
                    placeholder="Telefono Movil"
                  />
                     <span className="text-danger">
                    {registerInput.error_list.Telefono_Movil}
                  </span>
                </div>
              </div>
            </div>
            <div className="s-form-div">
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon2} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Subdominio"
                    onChange={handleInput}
                    value={registerInput.Subdominio}
                    id=""
                    className=""
                    placeholder="Subdominio Deseado"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon3} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Numero_Usuarios"
                    id=""
                    placeholder="Numero De Clientes"
                    onChange={handleInput}
                    value={registerInput.Numero_Usuarios}
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon5} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Pais"
                    onChange={handleInput}
                    value={registerInput.Pais}
                    id=""
                    className=""
                    placeholder="Pais"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon13} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Provincia"
                    onChange={handleInput}
                    value={registerInput.Provincia}
                    id=""
                    placeholder="Provincia"
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon8} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Domicilio"
                    onChange={handleInput}
                    value={registerInput.Domicilio}
                    id=""
                    className=""
                    placeholder="Domicilio"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon9} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Ciudad"
                    onChange={handleInput}
                    value={registerInput.Ciudad}
                    id=""
                    placeholder="Ciudad"
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon10} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Codigo_Postal"
                    onChange={handleInput}
                    value={registerInput.Codigo_Postal}
                    id=""
                    className=""
                    placeholder="Codigo Postal"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon11} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Email"
                    onChange={handleInput}
                    value={registerInput.Email}
                    id=""
                    placeholder="Email"
                  />
                </div>
              </div>
            </div>
          </div>

          <input type="submit" className="add-form" value="Añadir" />
        </form>

        <form
          onSubmit={registerSubmites}
          className="s-form-input"
          id="s-form-id"
        >
          <div className="form-reg">
            <div className="s-form-div">
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon6} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Nombre"
                    required
                    onChange={handleInput}
                    value={registerInput.Nombre}
                    id=""
                    className=""
                    placeholder="Nombre"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon14} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Dni"
                    required
                    onChange={handleInput}
                    value={registerInput.Dni}
                    id=""
                    placeholder="DNI/NIE"
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon16} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Telefono_Movil"
                    onChange={handleInput}
                    value={registerInput.Telefono_Movil}
                    id=""
                    placeholder="Telefono Movil"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon11} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Email"
                    onChange={handleInput}
                    value={registerInput.Email}
                    id=""
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field" id="select-input">
                  <div className="s-form-img-container">
                    <img src={icon4} alt="" />
                  </div>
                  <select
                    class="form-select"
                    name="Tipo_Empresa"
                    onChange={handleInput}
                    value={registerInput.Tipo_Empresa}
                  >
                    <option selected>Tipo Empresa</option>
                    <option>Correduria</option>
                    <option>Agente Exclusivo</option>
                  </select>
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon8} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Domicilio"
                    onChange={handleInput}
                    value={registerInput.Domicilio}
                    id=""
                    className=""
                    placeholder="Domicilio"
                  />
                </div>
              </div>
            </div>
            <div className="s-form-div">
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon2} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Subdominio"
                    onChange={handleInput}
                    value={registerInput.Subdominio}
                    id=""
                    className=""
                    placeholder="Subdominio Deseado"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon3} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Numero_Usuarios"
                    id=""
                    placeholder="Numero De Clientes"
                    onChange={handleInput}
                    value={registerInput.Numero_Usuarios}
                  />
                </div>
              </div>

              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon5} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Pais"
                    onChange={handleInput}
                    value={registerInput.Pais}
                    id=""
                    className=""
                    placeholder="Pais"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon13} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Provincia"
                    onChange={handleInput}
                    value={registerInput.Provincia}
                    id=""
                    placeholder="Provincia"
                  />
                </div>
              </div>
              <div className="s-form-input-box">
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon10} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Codigo_Postal"
                    onChange={handleInput}
                    value={registerInput.Codigo_Postal}
                    id=""
                    className=""
                    placeholder="Codigo Postal"
                  />
                </div>
                <div className="s-form-input-field">
                  <div className="s-form-img-container">
                    <img src={icon9} alt="" />
                  </div>
                  <input
                    type="text"
                    name="Ciudad"
                    onChange={handleInput}
                    value={registerInput.Ciudad}
                    id=""
                    placeholder="Ciudad"
                  />
                </div>
              </div>
            </div>
          </div>

          <input type="submit" className="add-form" value="Añadir" />
        </form>
      </section>
    </>
  );
};

export default Logineso;
