import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import logo from "../../assets/logo/logo.png";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import user from "../../assets/icons/user.png";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import profileImg from "../../assets/icons/user.png";
import "../../styles/profile.css";

const Editlegal = () => {
  const { id } = useParams();
  const user_id = id;
  const history = useHistory();
  const [imagedata, setImagedata] = useState("");
  const handleImage = (file) => {
    setImagedata(file[0]);
  };

  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function users() {
      const res = await axios.get("/api/current/user");
      if (res.data.status === 200) {
        setUsers(res.data.users);
      }
    }
    users();
  }, []);

  const [changeInput, setChange] = useState({
    Cif: "",
    Nombre_Fiscal: "",
    Nombre_Administrador: "",
    Dni: "",
    Domicilio_Fiscal: "",
    Telefono_Fijo: "",
    Telefono_Movil: "",
    Email: "",
    Tipo_Empresa: "",
    Numero_Usuarios: "",
    Pais: "",
    Provincia: "",
    Domicilio: "",
    Codigo_Postal: "",
    Ciudad: "",
    Subdominio: "",
    Forma_Pago: "",
    Nombre_Tarjeta: "",
    Numero_Tarjeta: "",
    CVV_Tarjeta: "",
    Caducidad_Suscripcion: "",
    file: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setChange({ ...changeInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    async function test() {
      const res = await axios.get(`/api/edit/legal/user/${user_id}`);
      if (res.data.status === 200) {
        setChange({
          id: res.data.users.id,
          Cif: res.data.users.CIF,
          Nombre_Fiscal: res.data.users.Nombre_Fiscal,
          Nombre_Administrador: res.data.users.Nombre_Administrador,
          Dni: res.data.users.DNI,
          Domicilio_Fiscal: res.data.users.Domicilio_Fiscal,
          Telefono_Fijo: res.data.users.Telefono_Fijo,
          Telefono_Movil: res.data.users.Telefono_Movil,
          Email: res.data.users.Email,
          Tipo_Empresa: res.data.users.Tipo_Empresa,
          Numero_Usuarios: res.data.users.Numero_Usuarios,
          Pais: res.data.users.Pais,
          Provincia: res.data.users.Provincia,
          Domicilio: res.data.users.Domicilio,
          Codigo_Postal: res.data.users.Codigo_Postal,
          Ciudad: res.data.users.Ciudad,
          Subdominio: res.data.users.Subdominio,
          Forma_Pago: res.data.users.Forma_Pago,
          Nombre_Tarjeta: res.data.users.Nombre_Tarjeta,
          Numero_Tarjeta: res.data.users.Numero_Tarjeta,
          CVV_Tarjeta: res.data.users.Cvv_Tarjeta,
          Caducidad_Suscripcion: res.data.users.Caducidad_Suscripcion,
        });
      }
    }
    test();
  }, []);

  const Update = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("file", imagedata);
    for (let key in changeInput) data.append(key, changeInput[key]);
    const res = await axios.post(`api/update/legal/users/${user_id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.data.status === 200) {
      swal({
        title: "Bien Hecho !",
        text: "Has editado tu perfil !",
        icon: "success",
        button: "Continuar...",
      });
      history.push("/user/profile/juridica");
    }
  };

  return (
    <>
      <div className="sidebar-background">
        <Sidebar />
      </div>
      <div className="container-home">
        <div>
          <img className="logo" src={logo} alt="logo" />
        </div>
        <Navbar />
      </div>

      <form onSubmit={Update} className="profile">
        <div className="left-profile">
          <div className="user-img">
            {users.file ? (
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + `/storage/${users.file}`
                }
                alt="user-foto"
              />
            ) : (
              <img src={profileImg} alt="user-foto" />
            )}{" "}
          </div>
          <div className="user-domain">
            <h2>Dominio</h2>
            <input
              type="text"
              name="Subdominio"
              disabled
              id="profile-input-center"
              placeholder="SI/NO"
              onChange={handleInput}
              value={changeInput.Subdominio}
            />
          </div>
          <div className="user-clients">
            <h2>Numero De Clientes</h2>
            <input
              type="text"
              name="Numero_Usuarios"
              disabled
              id="profile-input-center"
              placeholder="N° Clientes"
              onChange={handleInput}
              value={changeInput.Numero_Usuarios}
            />
          </div>

          <div className="user-clients">
            <h2>Foto De Perfil</h2>
            <input
              type="file"
              name="file"
              onChange={(e) => handleImage(e.target.files)}
              id="profile-input-center"
            />
          </div>
          <div className="user-edit">
            <td>
              <button id="create" className="btn btn-info btn-sm danger">
                Aplicar
              </button>
            </td>
          </div>
        </div>
        <div className="right-profile">
          <div className="profile-container">
            <div className="profile-box">
              <div className="profile-title">
                <h2>Detalles Empresa</h2>
              </div>
              <div className="profile-details">
                <h2>CIF</h2>
                <input
                  type="text"
                  name="Cif"
                  placeholder=""
                  disabled
                  id="profile-input"
                  onChange={handleInput}
                  value={changeInput.Cif}
                />
              </div>
              <div className="profile-details">
                <h2>Nombre Fiscal</h2>
                <input
                  type="text"
                  name="Nombre_Fiscal"
                  disabled
                  id="profile-input"
                  onChange={handleInput}
                  value={changeInput.Nombre_Fiscal}
                />
              </div>
              <div className="profile-details">
                <h2>Domicilio Fiscal</h2>
                <input
                  type="text"
                  name="Domicilio_Fiscal"
                  id="profile-input"
                  placeholder="Su Domicilio "
                  onChange={handleInput}
                  value={changeInput.Domicilio_Fiscal}
                />
              </div>
              <div className="profile-details">
                <h2>Tipo De Empresa</h2>
                <input
                  type="text"
                  name="Tipo_Empresa"
                  id="profile-input"
                  placeholder="Tipo Empresa"
                  onChange={handleInput}
                  value={changeInput.Tipo_Empresa}
                />
              </div>
            </div>
            <div className="profile-box">
              <div className="profile-title">
                <h2>Detalles Administrador</h2>
              </div>
              <div className="profile-details">
                <h2>Nombre Administrador</h2>
                <input
                  type="text"
                  name="Nombre_Administrador"
                  disabled
                  id="profile-input"
                  placeholder="Nombre Del Administrador"
                  onChange={handleInput}
                  value={changeInput.Nombre_Administrador}
                />
              </div>
              <div className="profile-details">
                <h2>DNI</h2>
                <input
                  type="text"
                  name="Dni"
                  id="profile-input"
                  disabled
                  placeholder="Su DNI"
                  onChange={handleInput}
                  value={changeInput.Dni}
                />
              </div>
              <div className="profile-details">
                <h2>Telefono Fijo</h2>
                <input
                  type="text"
                  name="Telefono_Fijo"
                  id="profile-input"
                  placeholder="Su Fijo"
                  onChange={handleInput}
                  value={changeInput.Telefono_Fijo}
                />
              </div>
              <div className="profile-details">
                <h2>Telefono Movil</h2>
                <input
                  type="text"
                  name="Telefono_Movil"
                  id="profile-input"
                  placeholder="Su Movil"
                  onChange={handleInput}
                  value={changeInput.Telefono_Movil}
                />
              </div>
              <div className="profile-details">
                <h2>Email</h2>
                <input
                  type="email"
                  name="Email"
                  id="profile-input"
                  placeholder="Email"
                  onChange={handleInput}
                  value={changeInput.Email}
                />
              </div>
            </div>

            <div className="profile-box">
              <div className="profile-title">
                <h2>Detalles Domicilio</h2>
              </div>
              <div className="profile-details">
                <h2>Pais</h2>
                <input
                  type="text"
                  name="Pais"
                  id="profile-input"
                  placeholder="Su Email"
                  onChange={handleInput}
                  value={changeInput.Pais}
                />
              </div>
              <div className="profile-details">
                <h2>Provincia</h2>
                <input
                  type="text"
                  name="Provincia"
                  placeholder="Provincia"
                  id="profile-input"
                  onChange={handleInput}
                  value={changeInput.Provincia}
                />
              </div>
              <div className="profile-details">
                <h2>Domicilio</h2>
                <input
                  type="text"
                  name="Domicilio"
                  id="profile-input"
                  placeholder="Domicilio"
                  onChange={handleInput}
                  value={changeInput.Domicilio}
                />
              </div>
              <div className="profile-details">
                <h2>Ciudad</h2>
                <input
                  type="text"
                  name="Ciudad"
                  placeholder="Ciudad"
                  id="profile-input"
                  onChange={handleInput}
                  value={changeInput.Ciudad}
                />
              </div>
              <div className="profile-details">
                <h2>Codigo Postal</h2>
                <input
                  type="text"
                  name="Codigo_Postal"
                  id="profile-input"
                  placeholder="18001"
                  onChange={handleInput}
                  value={changeInput.Codigo_Postal}
                />
              </div>
            </div>
          </div>
          <div className="profile-container">
            <div className="profile-box">
              <div className="profile-title">
                <h2>Detalles De Pago</h2>
              </div>
              <div className="profile-details">
                <h2>Forma De Pago</h2>
                {/* <h3>{profile.Forma_Pago}</h3> */}
              </div>
              <div className="profile-details">
                <h2>Nombre Tarjeta</h2>
                {/* <h3>{profile.Nombre_Tarjeta}</h3> */}
              </div>
              <div className="profile-details">
                <h2>Numero Tarjeta</h2>
                {/* <h3>{profile.Numero_Tarjeta}</h3> */}
              </div>
              <div className="profile-details">
                <h2>CVV Tarjeta</h2>
                {/* <h3>{profile.Cvv_Tarjeta}</h3> */}
              </div>
              <div className="profile-details">
                <h2>Caducidad Suscripcion</h2>
                {/* <h3>{profile.Caducidad_Suscripcion}</h3> */}
              </div>
            </div>
          </div>
        </div>
      </form>

      <Footer />
    </>
  );
};

export default Editlegal;
