import { React, useLayoutEffect } from "react";
import Contact from "../components/contact-form";
import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import logo from "../assets/logo/logo.png";
import Footer from "../components/footer";
import Whatsapp from "../components/whatsapp";

const Contacto = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <Whatsapp />

      <section className="s1">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
          <Contact />
        </header>
      </section>

      <Footer />
    </>
  );
};

export default Contacto;
