import {React, useLayoutEffect} from "react"
import Navbar from "../../components/navbar";
import logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import "../../styles/about.css";
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";

const Privacy_policy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <section className="">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
        </header>
      </section>
      <section className="s2-legal">
        <h1 className="s2-legal-title">POLÍTICA DE PRIVACIDAD</h1>
        <h2 className="s2-legal-title">1. INFORMACIÓN AL USUARIO</h2>
        <h3 className="s2-legal-title">Responsable del tratamiento.</h3>
        <h4 className="h4-legal">Identidad. DATA INSURANCE COMPANY, S.L. - B09911496</h4>
        <h4 className="h4-legal">
          Dirección. C/ Juan Carlos I, 55 - 28609 Sevilla la Nueva (Madrid)
        </h4>
        <h4 className="h4-legal">Teléfono. 600508333</h4>
        <h4 className="h4-legal">Correo electrónico. info@wedibo.es</h4>
        <h3 className="s2-legal-title">Finalidad del tratamiento</h3>
        <h4 className="h4-legal">
          DATA INSURANCE COMPANY, S.L. tratará la información facilitada por los
          Usuarios en cada uno de los apartados del sitio web con la siguientes
          finalidades:
        </h4>
        <h4 className="h4-legal">
          CONTACTO: Los datos serán tratados para la resolución de consultas y
          el envio de la información solicitada.
        </h4>
        <h4 className="h4-legal">
          SUSCRIPCIÓN A BOLETÍN DE NOTICIAS: Los datos se tratarán a efectos de
          la remisión de newsletter y noticias de actualidad que puedan resultar
          de interes a los Usuarios.
        </h4>
        <h4 className="h4-legal">
          TRABAJA CON NOSOTROS: Trataremos los datos facilitados para el examen
          de la idoneidad de los usuarios paras las distintas candidaturas y
          estudiar su incorporación a los correspondientes procesos de
          selección.
        </h4>
        <h4 className="h4-legal">
          ENVIO DE INFORMACIÓN COMERCIAL: DATA INSURANCE COMPANY, S.L.
          solicitará el consentimiento explicito de los usuarios de forma
          adicional para la remisión de comunicaciones publicitarias
          relacionadas con productos comercializados por la empresas.
        </h4>
        <h3 className="s2-legal-title">
          Criterios de conservación de los datos
        </h3>
        <h4 className="h4-legal">
          DATA INSURANCE COMPANY, S.L. conservará los datos mientras persista un
          interes legítimo y en tanto que el interesado no manifieste su
          oposición al tratamiento de los mismos.
        </h4>
        <h3 className="s2-legal-title">
          Legitimación para el tratamiento de los datos.
        </h3>
        <h4 className="h4-legal">
          El tratamiento de los datos del Usuario por parte de DATA INSURANCE
          COMPANY, S.L. tendrá su legitimación en el consentimiento
          explícitamente prestado.
        </h4>
        <h3 className="s2-legal-title">Comunicación de los datos</h3>.
        <h4 className="h4-legal">
          Los datos facilitados por los Usuarios podrán ser cedidos a las
          Fuerzas y Cuerpos de Seguridad y/o otras Administraciones Públicas con
          competencias en la materia.
        </h4>
        <h3 className="s2-legal-title">Derechos que asisten al Usuario.</h3>
        <h4 className="h4-legal">
          Cualquier Usuario tiene derecho a obtener información sobre si DATA
          INSURANCE COMPANY, S.L. está tratando datos personales que le
          conciernen o no y, en tal caso, a ejercer su derecho de acceso a los
          datos personales y a la siguiente información:
        </h4>
        <h4 className="h4-legal">a) los fines del tratamiento.</h4>
        <h4 className="h4-legal">b) las categorías de datos personales de que se trate.</h4>
        <h4 className="h4-legal">
          c) los destinatarios o las categorías de destinatarios a los que se
          comunicaron o serán comunicados los datos personales, en particular
          destinatarios en terceros u organizaciones internacionales.
        </h4>
        <h4 className="h4-legal">
          d) de ser posible, el plazo previsto de conservación de los datos
          personales o, de no ser posible, los criterios utilizados para
          determinar este plazo.
        </h4>
        <h4 className="h4-legal">
          e) la existencia del derecho a solicitar del responsable la
          rectificación o supresión de datos personales o la limitación del
          tratamiento de datos
        </h4>
        <h4 className="h4-legal">
          personales relativos al interesado, o a oponerse a dicho tratamiento;
        </h4>
        <h4 className="h4-legal">
          f) el derecho a presentar una reclamación ante una autoridad de
          control.
        </h4>
        <h4 className="h4-legal">
          Asimismo, el Usuario tiene derecho a solicitar la rectificación de los
          datos inexactos o, en su caso, a solicitar la supresión de los mismos
          cuando, entre otros motivos, los datos ya no sean necesarios para los
          fines que fueron recogidos o cuando usted decida retirar el
          consentimiento en que se hubiera basado el tratamiento hasta la fecha.
        </h4>
        <h4 className="h4-legal">
          Los Usuarios podrán además solicitar la limitación del tratamiento de
          los datos de los interesados, en cuyo caso únicamente serán
          conservados por DATA INSURANCE COMPANY, S.L. para el ejercicio o la
          defensa de reclamaciones.
        </h4>
        <h4 className="h4-legal">
          DATA INSURANCE COMPANY, S.L. dejará de tratar sus datos siempre que
          usted lleve a cabo la oposición a dicho tratamiento, salvo por motivos
          legítimos imperiosos, o por el ejercicio o la defensa de posibles
          reclamaciones.
        </h4>
        <h4 className="h4-legal">
          Finalmente, y en ejercicio de su derecho a la portabilidad de los
          datos, el Usuario podrá recibir los datos personales que hubiera
          proporcionado a DATA INSURANCE COMPANY, S.L., en un formato
          estructurado, de uso común y legible por máquina, así como a solicitar
          que se transmitan a otra entidad responsable del tratamiento.
        </h4>
        <h3 className="s2-legal-title">Medios para ejercer sus derechos.</h3>
        <h4 className="h4-legal">
          El Usuario podrá ejercer materialmente los derechos descritos en el
          apartado anterior solicitando el correspondiente formulario a la
          dirección del responsable: info@wedibo.es
        </h4>
        <h4 className="h4-legal">
          Adicionalmente, el Usuario podrá solicitar información en todo momento
          acerca del ejercicio de los derechos que le incumben ante la Agencia
          Española de Protección de Datos.
        </h4>
        <h4 className="h4-legal">
          En caso de que el ejercicio de sus derechos no hubiera sido atendido o
          satisfecho correctamente, el Usuario podrá presentar la
          correspondiente reclamación ante la Agencia Española de Protección de
          Datos, ubicada en la Calle de Jorge Juan, 6, 28001, Madrid, teléfono
          912663517
        </h4>
        <h2 className="s2-legal-title">
          2. CARÁCTER OBLIGATORIO O FACULTATIVO DE LA INFORMACIÓN FACILITADA POR
          EL USUARIO
        </h2>
        <h4 className="h4-legal">
          Los Usuarios, mediante la marcación de las casillas correspondientes y
          entrada de datos en los campos, marcados con un asterisco (*) en el
          formulario de contacto o presentados en formularios de descarga,
          aceptan expresamente y de forma libre e inequívoca, que sus datos son
          necesarios para atender su petición, por parte del prestador, siendo
          voluntaria la inclusión de datos en los campos restantes. El Usuario
          garantiza que los datos personales facilitados al RESPONSABLE son
          veraces y se hace responsable de comunicar cualquier modificación de
          los mismos.
        </h4>
        <h4 />
        <h4 className="h4-legal">
          El RESPONSABLE informa y garantiza que todos los datos solicitados a
          través del sitio web son obligatorios, ya que son necesarios para la
          prestación de un servicio óptimo al Usuario. En caso de que no sean
          facilitados todos los datos, no se garantiza que la información y
          servicios facilitados sean completamente ajustados a sus necesidades.
        </h4>
        <h2 className="s2-legal-title">3. MEDIDAS DE SEGURIDAD</h2>
        <h4 className="h4-legal">
          Que de conformidad con lo dispuesto en las normativas vigentes en
          protección de datos personales, el RESPONSABLE está cumpliendo con
          todas las disposiciones de las normativas GDPR y LOPD para el
          tratamiento de los datos personales de su responsabilidad, y
          manifiestamente con los principios descritos en el artículo 5 del GDPR
          y en el artículo 4 de la LOPD, por los cuales son tratados de manera
          lícita, leal y transparente en relación con el interesado y adecuados,
          pertinentes y limitados a lo necesario en relación con los fines para
          los que son tratados.
        </h4>
        <div>
          <h4 className="h4-legal">
            El RESPONSABLE garantiza que ha implementado políticas técnicas y
            organizativas apropiadas para aplicar las medidas de seguridad que
            establecen el GDPR y la LOPD con el fin de proteger los derechos y
            libertades de los Usuarios y les ha comunicado la información
            adecuada para que puedan ejercerlos.
          </h4>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Privacy_policy;
