import { React, useLayoutEffect } from "react";
import Navbar from "../../components/navbar";
import logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import "../../styles/about.css";
import Footer from "../../components/footer";
import Sidebar from "../../components/sidebar";

const Cookies_policy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <section className="">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
        </header>
      </section>
      <section className="s2-legal">
        <h1 className="s2-legal-title">COOKIES : Política de cookies</h1>
        <p />
        <h2 className="s2-legal-title">INFORMACIÓN SOBRE COOKIES</h2>
        <h4 className="h4-legal">
          Debido a la entrada en vigor de la referente modificación de la “Ley
          de Servicios de la Sociedad de la Información” (LSSICE) establecida
          por el Real Decreto 13/2012, es de obligación obtener el
          consentimiento expreso del usuario de todas las páginas web que usan
          cookies prescinWEDIBOles, antes de que éste navegue por ellas.
        </h4>
        <p />
        <h2 className="s2-legal-title">¿QUÉ SON LAS COOKIES?</h2>
        <h4 className="h4-legal">
          Las cookies y otras tecnologías similares tales como local shared
          objects, flash cookies o píxeles, son herramientas empleadas por los
          servidores Web para almacenar y recuperar información acerca de sus
          visitantes, así como para ofrecer un correcto funcionamiento del
          sitio.
        </h4>
        <h4 className="h4-legal">
          Mediante el uso de estos dispositivos se permite al servidor Web
          recordar algunos datos concernientes al usuario, como sus preferencias
          para la visualización de las páginas de ese servidor, nombre y
          contraseña, productos que más le interesan, etc.
        </h4>
        <p />
        <h2 className="s2-legal-title">
          COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS
        </h2>
        <p />
        <h4 className="h4-legal">
          Según la directiva de la UE, las cookies que requieren el
          consentimiento informado por parte del usuario son las cookies de
          analítica y las de publicidad y afiliación, quedando exceptuadas las
          de carácter técnico y las necesarias para el funcionamiento del sitio
          web o la prestación de servicios expresamente solicitados por el
          usuario.
        </h4>
        <p />
        <h2 className="s2-legal-title">¿QUÉ TIPOS DE COOKIES EXISTEN?</h2>
        <h4 className="h4-legal">
          Sobre los tipos de cookies, existen cinco grandes grupos:
        </h4>
        <h4 className="h4-legal">
          Cookies analíticas: recogen información del uso que se realiza del
          sitio web.
        </h4>
        <h4 className="h4-legal">
          Cookies sociales: son aquellas necesarias para redes sociales
          externas.
        </h4>
        <h4 className="h4-legal">
          Cookies de afiliados: permiten hacer un seguimiento de las visitas
          procedentes de otras webs, con las que el sitio web establece un
          contrato de afiliación (empresas de afiliación).
        </h4>
        <h4 className="h4-legal">
          Cookies de publicidad y comportamentales: recogen información sobre
          las preferencias y elecciones personales del usuario (retargeting).
        </h4>
        <h4 className="h4-legal">
          Cookies técnicas y funcionales: son las estrictamente necesarias para
          el uso del sitio web y para la prestación del servicio contratado.
        </h4>
        <h2 className="s2-legal-title">
          COOKIES QUE SE UTILIZAN EN ESTE SITIO WEB
        </h2>
        {/*<b>PHPSESSID:</b>
          <h4 className="h4-legal">
            cookie técnica y estrictamente necesaria que contiene el
            identificador de la sesión. Se elimina al cerrar el navegador.
          </h4>
          <b>cookieconsent_status:</b>
          <h4 className="h4-legal">
            cookie técnica y estrictamente necesaria que contiene el valor de si
            se ha aceptado la instalación de cookies. Caduca en 7 días desde la
            última actualización.
          </h4>
          <b>laravel_session:</b>
          <h4 className="h4-legal">
            cookie técnica y estrictamente necesaria que contiene el
            identificador de la sesión. Se elimina al cerrar el navegador.
          </h4>
          <b>XSRF-TOKEN:</b>
          <h4 className="h4-legal">
            cookie técnica y estrictamente necesaria que contiene el
            identificador de la sesión. Se elimina al cerrar el navegador.
          </h4>
          <b>_lang:</b>
          <h4 className="h4-legal">
            cookie técnica y estrictamente necesaria que contiene el idioma
            de la sesión. Se elimina al cerrar el navegador.
          </h4>
          <b>ac_cookies:</b>
          <h4 className="h4-legal">
             cookie técnica y estrictamente necesaria que contiene el valor
            de si se ha aceptado la instalación de cookies. Caduca en 1 año
            desde la última actualización.
          </h4>
          <b>_ga:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics que habilita la función de control de
            visitas únicas. La primera vez que un usuario entre en el sitio web
            a través de un navegador se instalará esta cookie. Cuando este
            usuario vuelva a entrar en la web con el mismo navegador, la cookie
            considerará que es el mismo usuario. Solo en el caso de que el
            usuario cambie de navegador, se considerará otro usuario. Caduca a
            los 2 años desde la última actualización.
          </h4>
          <b>_gat:</b>
          <h4 className="h4-legal">
            Esta cookie se asocia con Google Analytics Universal. Se utiliza
            para limitar la velocidad de petición - la limitación de la recogida
            de datos en los sitios de alto tráfico. Caduca a los 10 minutos.
          </h4>
          <b>_utma:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics que registra la fecha de la primera
            y última vez que el usuario vistió el sitio web. Caduca a los 2 años
            desde la última actualización.
          </h4>
          <b>_utmb:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics que registra la hora de llegada a la
            página web. Caduca a los 30 minutos desde la última actualización.
          </h4>
          <b>_utmc:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics utilizada para la interoperabilidad con
            el código de seguimiento urchin.js. Se elimina al cerrar el
            navegador.
          </h4>
          <b>_utmt:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics. Esta cookie se utiliza para procesar el
            tipo de solicitud pedida por el usuario. Caduca al finalizar la
            sesión.
          </h4>
          <b>_utmv:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics. Esta cookie se utiliza para segmentar
            datos demográficos. Caduca al finalizar la sesión.
          </h4>
          <b>_utmz:</b>
          <h4 className="h4-legal">
            cookie de Google Analytics que almacena la fuente de tráfico o una
            campaña para explicar cómo el usuario llegó al sitio web. Caduca a
            los 6 meses desde la última actualización.
          </h4>*/}
        <div className="table-overflow">
          <table>
            <thead>
              <tr>
                <th scope="col">Nombre de la cookie</th>
                <th scope="col">Proveedor</th>
                <th scope="col">Finalidad</th>
                <th scope="col">Duración</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>XSRF-Token</td>
                <td>WEDIBO</td>
                <td>Se utiliza para proteger al usuario de un ataque XSRF.</td>
                <td>1 hora</td>
              </tr>
              <tr>
                <td>PHPSESSID</td>
                <td>WEDIBO</td>
                <td>
                  La cookie de sesión, es necesaria para reconocer al usuario
                  entre los cambios de página.
                </td>
                <td>Sesión</td>
              </tr>
              <tr>
                <td>laravel_session</td>
                <td>WEDIBO</td>
                <td>
                  Se utiliza para almacenar el estado de sesión del usuario en
                  una aplicación Laravel.
                </td>
                <td>1 hora</td>
              </tr>
              <tr>
                <td>cookieconsent_status</td>
                <td>WEDIBO</td>
                <td>
                  Se utiliza para almacenar el estado de aceptación de las
                  cookies.
                </td>
                <td>1 hora</td>
              </tr>
              {/*<tr>
                <td>_ga</td>
                <td>Google Analytics</td>
                <td>
                  Habilita la función de control de visitas únicas. La primera
                  vez que un usuario entre en el sitio web a través de un
                  navegador se instalará esta cookie. Cuando este usuario vuelva
                  a entrar en la web con el mismo navegador, la cookie
                  considerará que es el mismo usuario. Solo en el caso de que el
                  usuario cambie de navegador, se considerará otro usuario.
                </td>
                <td>2 años</td>
        </tr>*/}
            </tbody>
          </table>
        </div>

        <h2 className="s2-legal-title">
          REVOCACIÓN DEL CONSENTIMIENTO PARA INSTALAR COOKIES COMO ELIMINAR LAS
          COOKIES DEL NAVEGADOR
        </h2>

        <h3 className="s2-legal-title">Chrome</h3>
        <h4 className="h4-legal">1. Selecciona el icono de Herramientas</h4>
        <h4 className="h4-legal">2. Haz clic en Configuración.</h4>
        <h4 className="h4-legal">3. Haz clic en Mostrar Opciones Avanzadas.</h4>
        <h4 className="h4-legal">
          4. En la sección "Privacidad" haz clic en Configuración de contenido.
        </h4>
        <h4 className="h4-legal">
          &nbsp; &nbsp;• Eliminar cookies: Haz clic en Todas las cookies y los
          datos de sitios…
        </h4>
        <h4 className="h4-legal">
          &nbsp; &nbsp;• No permitir que se almacenen cookies.
        </h4>
        <h4 className="h4-legal">
          5. Haz clic en Eliminar datos de navegación (vaciar la Caché).
        </h4>
        <h4 className="h4-legal">6. Cierra y reinicia el navegador.</h4>
        <h4 className="h4-legal"></h4>
        <h4 className="h4-legal">
          Para más información sobre Chrome pulse&nbsp;
          <a
            href="http://support.google.com/chrome/answer/95647?hl=es"
            target="_blank"
          >
            aquí
          </a>
          .
        </h4>
        <h3 className="s2-legal-title">Internet Explorer. Versión 11</h3>
        <h4 className="h4-legal">
          1. Selecciona Herramientas | Opciones de Internet.
        </h4>
        <h4 className="h4-legal">2. Haz clic en la ficha General.</h4>
        <h4 className="h4-legal">
          3. En la sección "Historial de exploración", haz clic en Eliminar el
          historial de exploración al salir.
        </h4>
        <h4 className="h4-legal">4. Seleccionar Eliminar archivos.</h4>
        <h4 className="h4-legal">5. Seleccionar Eliminar cookies.</h4>
        <h4 className="h4-legal">6. Haz clic en Eliminar.</h4>
        <h4 className="h4-legal">7. Haz clic en Aceptar.</h4>
        <h4 className="h4-legal">8. Cierra y reinicia el navegador.</h4>
        <h4 className="h4-legal">
          Para más información sobre Internet Explorer pulse&nbsp;
          <a
            href="http://windows.microsoft.com/es-419/windows7/how-to-manage-cookies-in-internet-explorer-9"
            target="_blank"
          >
            aquí
          </a>
          .
        </h4>
        <h3 className="s2-legal-title">Firefox. Versión 18</h3>
        <h4 className="h4-legal">
          1. Selecciona Firefox | Historial | Limpiar el historial reciente.
        </h4>
        <h4 className="h4-legal">
          2. Al lado de "Detalles", haz clic en la flecha hacia abajo.
        </h4>
        <h4 className="h4-legal">
          3. Selecciona las siguientes casillas de verificación: Cookies, Caché,
          Inicios de sesión activos
        </h4>
        <h4 className="h4-legal">
          4. Usando el "Intervalo de tiempo para borrar" en el menú desplegable,
          selecciona Todo.
        </h4>
        <h4 className="h4-legal">5. Haz clic en Borrar ahora.</h4>
        <h4 className="h4-legal">6. Cierra y reinicia el navegador.</h4>
        <h4 className="h4-legal">
          Puede aceptar o rechazar las cookies individualmente en las
          Preferencias de Firefox, en la sección Historial disponible en
          Herramientas &gt; Opciones &gt; Privacidad.
        </h4>
        <h4 className="h4-legal">
          Para más información sobre Mozilla Firefox pulse&nbsp;
          <a
            href="https://www.mozilla.org/es-ES/privacy/websites/#cookies"
            target="_blank"
          >
            aquí
          </a>
          .
        </h4>
        <h3 className="s2-legal-title">Safari Versión 5.1</h3>
        <h4 className="h4-legal">
          1. Selecciona el icono de Safari / Editar | Restaurar Safari.
        </h4>
        <h4 className="h4-legal">
          2. Selecciona las siguientes casillas de verificación: Borrar el
          historial, Eliminar todos los datos de sitio web
        </h4>
        <h4 className="h4-legal">3. Haz clic en Restablecer.</h4>
        <h4 className="h4-legal">4. Cierra y reinicia el navegador.</h4>
        <h4 className="h4-legal">
          Para más información sobre Safari pulse&nbsp;
          <a href="http://support.apple.com/kb/PH5042">aquí</a>.
        </h4>
        <h3 className="s2-legal-title">Opera</h3>
        <h4 className="h4-legal">Opciones - Avanzado - Cookies.</h4>
        <h4 className="h4-legal">
          Las opciones de cookies controlan el modo en que Opera los maneja y
          por lo tanto su aceptación o rechazo.
        </h4>
        <h4 className="h4-legal">
          Para más información sobre Ópera pulse&nbsp;
          <a
            href="http://help.opera.com/Linux/10.60/es-ES/cookies.html"
            target="_blank"
          >
            aquí
          </a>
          .
        </h4>
        <h3 className="s2-legal-title">Otros navegadores</h3>
        <h4 className="h4-legal">
          Consulte la documentación del navegador que tenga instalado.
        </h4>
      </section>

      <Footer />
    </>
  );
};

export default Cookies_policy;
