import React from 'react';
import './App.css';
import AdminPrivateRoute from './AdminPrivateRoute';
import UserPrivateRoute from './UserPrivateRoute';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import About from './pages/about';
import Login from './pages/login';
import axios from 'axios';
import Tutoriales from './pages/tutoriales';
import Contacto from './pages/contact';
import Profile from './pages/user/profile';
import Admincontactos from './pages/admin/admin-contactos';
import Adminusuarios from './pages/admin/admin-users';
import Adminjuridicas from './pages/admin/admin-juridicas';
import Adminfisicas from './pages/admin/admin-fisicas';
import RegisterFisicas from './pages/admin/admin-fisicas-create';
import RegisterJuridicas from './pages/admin/admin-juridicas-create';
import RegisterUsuarios from './pages/admin/admin-usuarios-create';
import ProfileFisica from './pages/user/profile-fisica';
import Editlegal from './pages/user/user-edit-profile-juridica';
import EditUser from './pages/admin/admin-usuarios-edit';
import ShowJuridicas from './pages/admin/admin-juridicas-show';
import EditJuridicas from './pages/admin/admin-juridicas-edit';
import ShowFisicas from './pages/admin/admin-fisicas-show';
import EditFisicas from './pages/admin/admin-fisicas-edit';
import Editphysical from './pages/user/user-edit-profile-fisica';
import Logineso from './components/payment-formes';
import Planes from './pages/planes';
import RegisterContacto from './pages/admin/admin-contactos-create';
import ShowContacto from './pages/admin/admin-contactos-show';
import EditContacto from './pages/admin/admin-contactos-edit';
import NewPassword from './pages/new-password';
import ResetPassword from './pages/reset-password';
import Cookies_policy from "./pages/legal/cookies-policy";
import Legal_advice from "./pages/legal/legal-advice";
import Privacy_policy from "./pages/legal/privacy-policy";
import Adminmensajes from './pages/admin/admin-mensajes';
import ShowMensajes from './pages/admin/admin-mensajes-show';
import Pago from './pages/pago';

axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'multipart/form-data';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});


function App() {
  return (
    <Router>

      <Switch>

        <Route exact path="/">
          <Homepage />
        </Route>



        <Route exact path="/Pago">
          <Pago />
        </Route>

        <Route path="/About">
          <About />
        </Route>

        <Route path="/Login">
          <Login />
        </Route>

        <Route path="/Tutoriales">
          <Tutoriales />
        </Route>

        <Route path="/Contact">
          <Contacto />
        </Route>

        <Route path="/Reset/Password">
          <ResetPassword />
        </Route>


        <Route path="/New/Password/:token">
          <NewPassword />
        </Route>

        <Route path="/Cookies-policy">
          <Cookies_policy />
        </Route>

        <Route path="/Legal-advice">
          <Legal_advice />
        </Route>

        <Route path="/Privacy-policy">
          <Privacy_policy />
        </Route>
        

        {/* ADMIN-ROUTES */}
        
        <AdminPrivateRoute exact path='/admin/usuarios' component={Adminusuarios} />
        <AdminPrivateRoute exact path='/admin/usuarios/create' component={RegisterUsuarios} />
        <AdminPrivateRoute exact path='/admin/usuarios/edit/:id' component={EditUser} />

        <AdminPrivateRoute exact path='/admin/juridicas' component={Adminjuridicas} />
        <AdminPrivateRoute path="/admin/juridicas/create" component={RegisterJuridicas} />
        <AdminPrivateRoute path="/admin/juridicas/show/:id" component={ShowJuridicas} />
        <AdminPrivateRoute path="/admin/juridicas/edit/:id" component={EditJuridicas} />

        <AdminPrivateRoute exact path='/admin/fisicas' component={Adminfisicas} />
        <AdminPrivateRoute path="/admin/fisicas/create" component={RegisterFisicas} />
        <AdminPrivateRoute path="/admin/fisicas/edit/:id" component={EditFisicas} />
        <AdminPrivateRoute path="/admin/fisicas/show/:id" component={ShowFisicas} />

        <AdminPrivateRoute exact path='/admin/contactos' component={Admincontactos} />
        <AdminPrivateRoute exact path='/admin/contactos/create' component={RegisterContacto} />
        <AdminPrivateRoute path="/admin/contactos/show/:id" component={ShowContacto} />
        <AdminPrivateRoute path="/admin/contactos/edit/:id" component={EditContacto} />


        <AdminPrivateRoute exact path='/admin/mensajes' component={Adminmensajes} />
        <AdminPrivateRoute path="/admin/mensajes/show/:id" component={ShowMensajes} />

        {/* USER-ROUTES */}

        <UserPrivateRoute path="/user/legal/edit/:id" component={Editlegal} />
        <UserPrivateRoute path="/user/physical/edit/:id" component={Editphysical} />
        <UserPrivateRoute path="/user/complete/registration/:id" component={Logineso} />
        <UserPrivateRoute path="/user/profile/juridica" component={Profile} />
        <UserPrivateRoute path="/user/profile/fisica" component={ProfileFisica} />
        <UserPrivateRoute path="/Tutoriales" component={Tutoriales} />
      


      </Switch>

    </Router>
  );
}

export default App;
