import React from "react";
import "../styles/login.css";
import swal from 'sweetalert';
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useState } from "react";
import logo from "../assets/logo/icon.ico";

const Login = () => {

    const history = useHistory();


    const toggleForm = () => {
        let container = document.getElementById('container');
        let section = document.querySelector('section');
        container.classList.toggle('active');
        section.classList.toggle('active');
    }

    const [registerInput, setRegister] = useState({
        username: "",
        email: "",
        password: "",
        confirmed: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }
    const [imagedata, setImagedata] = useState({
        file: "",
    });

    const handleImage = (file) => {
        setImagedata(file[0]);
    };


    const registerSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("file", imagedata);
        data.append("username", registerInput.username);
        data.append("email", registerInput.email);
        data.append("password", registerInput.password);
        data.append("confirmed", registerInput.confirmed);

        await axios.post(process.env.REACT_APP_PUBLIC_URL+'/api/register', data, {headers: {Accept: 'application/json'}}).then(res => {

            if (res.data.status === 200) {
                // localStorage.setItem('auth_name', res.data.username);
                // localStorage.setItem('auth_token', res.data.token);

                setRegister({
                    username: '',
                    email: '',
                    password: '',
                    confirmed: '',
                    file: '',
                });
                swal({
                    title: "¡Registro completado!",
                    text: "Le hemos enviado un correo de verificacion a su email",
                    button: "Continuar",
                  }).then(() => {
                    window.location.reload(); // Reload the page
                  });
                console.log(res);
               
               
            }
            else {
                
                setRegister({ ...registerInput, error_list: res.data.validation_errors });
            }

        });

    }


    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
        error_list: [],

    });

    const handleInputs = (e) => {
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }


    const loginSubmit = (e) => {

        e.preventDefault();
        const data = {
            email: loginInput.email,
            password: loginInput.password,
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`api/login`, data).then(res => {
                if (res.data.status === 200) {
                    localStorage.setItem('auth_name', res.data.username);
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('tipo', res.data.tipo);
                    if (res.data.type === '1') {
                        history.push('/admin/usuarios');

                    }
                    else if (res.data.tipo === 1){
                        history.push('/user/profile/juridica');
                    }
                    else if (res.data.tipo === 2){
                        history.push('/user/profile/fisica');
                    }
                    else if (res.data.tipo === null){
                        history.push(`/user/complete/registration/${res.data.id}`);
                    }

                }
                else if (res.data.status === 401) {
                    swal("Warning", res.data.message, "warning");

                }
                // else if (res.data.status === 403) {
                //     swal("Warning", res.data.message, "warning");
                //     localStorage.removeItem('auth_name', res.data.username);
                //     localStorage.removeItem('auth_token', res.data.token);
                //     history.push('/login')
                // }
                else {
                    console.log(res.data)
                    setLogin({ ...loginInput, error_list: res.data.validation_errors });
                }
            });

        });

    }


    return (
        <>

            <section className='section'>
                <div className="container" id='container'>
                    <div className="user signinBx">
                        <div className="imgBx">
                            <img src={logo} />
                        </div>
                        <div className="formBx">
                            <form onSubmit={loginSubmit}>
                                <h2>Inicia Sesion</h2>
                                <input type="text" placeholder="Email"  name='email' onChange={handleInputs} value={loginInput.email} />
                                <span className="text-danger">{loginInput.error_list.email}</span>
                                <input type="password" placeholder="Contraseña"  name='password' onChange={handleInputs} value={loginInput.password} />
                                <span className="text-danger">{loginInput.error_list.password}</span>
                                <input type="submit" value="Conéctate" />
                                <p className="signup">¿No tienes aún una cuenta? <a href="#" onClick={toggleForm}>Regístrate</a></p>
                                <p className="signup"> <Link to="/reset/password">¿Has olvidado tu contraseña?</Link></p>

                            </form>
                        </div>
                    </div>

                    <div className="user signupBx">
                        <div className="imgBx">
                            <img src={logo} />
                        </div>
                        <div className="formBx">
                            <form onSubmit={registerSubmit}>
                                <h2>Crea una cuenta</h2>
                                <input type="text" placeholder="Usuario" name='username' onChange={handleInput} value={registerInput.username} />
                                <span className="text-danger">{registerInput.error_list.username}</span>
                                <input type="text" placeholder="Email" name='email' onChange={handleInput} value={registerInput.email} />
                                <span className="text-danger">{registerInput.error_list.email}</span>
                                <input type="password" placeholder="Crea Una Contraseña" name='password' onChange={handleInput} value={registerInput.password} />
                                <span className="text-danger">{registerInput.error_list.password}</span>
                                <input type="password" placeholder="Confirma Tu Contraseña" name='confirmed' onChange={handleInput} value={registerInput.confirmed} />
                                <span className="text-danger">{registerInput.error_list.confirmed}</span>
                                {/* <input type="file" name='file' id='image' onChange={e => handleImage(e.target.files)} /> */}
                                {/* <div className="check-marketing">
                                <input type="checkbox" id="scales" name="scales" />
                                <label for="scales" className="label-marketing">Quiero seguir recibiendo información sobre nuevos productos y servicios.</label>
                                </div>    */}
                                <div className="check-marketing">
                                    <input type="checkbox" required id="scales" name="scales" />
                                    <label for="scales" className="label-marketing">
                                        He leído y acepto la{" "}
                                        <Link to="/Privacy-policy">
                                        <b className="politica">política de privacidad</b>
                                        </Link>.
                                    </label>
                                </div>
                                
                                <input type="submit" value="Registrate" />
                                <p className="signup">¿Ya tienes una cuenta? <a href="#" onClick={toggleForm}>Inicia sesión</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )

}

export default Login;