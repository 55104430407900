import { React, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/homepage.css";
import Navbar from "../components/navbar";
import logo from "../assets/logo/logo.png";
import image1 from "../assets/images/homepage-1.png";
import image2 from "../assets/images/homepage-2.png";
import image3 from "../assets/images/homepage-3.png";
import image4 from "../assets/images/homepage-4.png";
import image5 from "../assets/images/homepage-5.png";
import image6 from "../assets/images/homepage-6.png";
import image8 from "../assets/images/homepage-8.png";
import Footer from "../components/footer";
import Plans from "../components/plans";
import Acordeon from "../components/acordeon";
import Demo from "../components/demo";
import Sidebar from "../components/sidebar";
import Whatsapp from "../components/whatsapp";

const Homepage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <Whatsapp />

      <section className="s1">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
          <main>
          <div className="container-svg">
            <svg viewBox="0 0 450 250" preserveAspectRatio="xMinYMin meet">
              <path
                d="M0,200 C150,300 350,0 500,50 L500,00 L0,0 Z"
                style={{ stroke: "none", fill: "#00477d" }}
              ></path>
            </svg>
          </div>
          <div className="container-svg-responsive">
            <svg viewBox="0 0 320 339" preserveAspectRatio="xMidYMid meet">
              <g
                transform="translate(0.000000,339.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M0 1705 l0 -1685 28 -7 c15 -4 90 -7 167 -7 215 0 376 40 600 149
233 113 431 261 670 500 241 241 446 512 605 801 131 238 317 498 497 695 166
182 327 317 529 444 l99 63 3 366 2 366 -1600 0 -1600 0 0 -1685z"
                  style={{ stroke: "none", fill: "#00477d" }}
                />
              </g>
            </svg>
          </div>
          
            <div className="left">
              <h1>CRM para comerciales de seguros</h1>
              <h2>
                WeDIBO nace de la necesidad y las carencias del control sobre
                nuestros propios datos y nuestros gestores.
              </h2>

              <Link to={"/Contact"}>
                <h4>Contáctanos</h4>
              </Link>
            </div>
            <div className="right">
              <img src={image1} alt="image1" />
            </div>
          </main>
        </header>
      </section>

      <Plans />

      <section className="s2">
        <img className="absolute-line" src={image6} alt="line" />
        <div className="box">
          <div className="s2-container">
            <div className="s2-container-image">
              <img src={image2} alt="image2" />
            </div>
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>1.</h2>
                <h3>Ahorra tiempo</h3>
              </div>
              <div className="s2-text">
                <h4>
                  Sabemos que el tiempo es oro y por eso hemos creado una
                  herramienta donde tener todo lo que necesitas a tu alcance en
                  unos segundos.
                </h4>
              </div>
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>2.</h2>
                <h3>Acceso a tu información</h3>
              </div>
              <div className="s2-text">
                <h4>
                  Ten acceso a los datos de tus clientes y a toda su
                  documentación desde cualquier dispositivo y en cualquier
                  lugar.
                </h4>
              </div>
            </div>
            <div className="s2-container-image">
              <img src={image3} alt="image3" />
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-image">
              <img src={image4} alt="image4" />
            </div>
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>3.</h2>
                <h3>Control sobre las gestiones</h3>
              </div>
              <div className="s2-text">
                <h4>
                  Obtén el control total sobre las gestiones propias y las de
                  tus empleados para no perder ninguna oportunidad de venta.
                </h4>
              </div>
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>4.</h2>
                <h3>Control de acceso</h3>
              </div>
              <div className="s2-text">
                <h4>
                  Controla el acceso de tus empleados desde el mismo lugar donde
                  pueden realizar todas las gestiones de tus clientes.
                </h4>
              </div>
            </div>
            <div className="s2-container-image">
              <img src={image5} alt="image5" />
            </div>
          </div>
        </div>
      </section>

      <section className="s2-responsive">
        <img className="absolute-line" src={image6} alt="line" />
        <div className="box">
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>1.</h2>
                <h3>Ahorra tiempo</h3>
              </div>
              <div className="s2-container-image">
                <img src={image2} alt="image2" />
              </div>
              <div className="s2-text">
                <h4>
                  Sabemos que el tiempo es oro y por eso hemos creado una
                  herramienta donde tener todo lo que necesitas a tu alcance en
                  unos segundos.
                </h4>
              </div>
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>2.</h2>
                <h3>Acceso a tu información</h3>
              </div>
              <div className="s2-container-image">
                <img src={image3} alt="image3" />
              </div>
              <div className="s2-text">
                <h4>
                  Ten acceso a los datos de tus clientes y a toda su
                  documentación desde cualquier dispositivo y en cualquier
                  lugar.
                </h4>
              </div>
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>3.</h2>
                <h3>Control sobre las gestiones</h3>
              </div>
              <div className="s2-container-image">
                <img src={image4} alt="image4" />
              </div>
              <div className="s2-text">
                <h4>
                  Obtén el control total sobre las gestiones propias y las de
                  tus empleados para no perder ningún cliente potencial.
                </h4>
              </div>
            </div>
          </div>
          <div className="s2-container">
            <div className="s2-container-text">
              <div className="s2-title">
                <h2>4.</h2>
                <h3>Control de acceso</h3>
              </div>
              <div className="s2-container-image">
                <img src={image5} alt="image5" />
              </div>
              <div className="s2-text">
                <h4>
                  Controla el acceso de tus empleados desde el mismo lugar donde
                  pueden realizar todas las gestiones de tus clientes.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Demo />
      <section className="s3">
        <div className="s3-img">
          <img src={image8} alt="faq" />
        </div>
        <div className="s3-faq">
          <div className="faq-title">
            <h2>Preguntas frecuentes</h2>
          </div>

          <div className="faq">
            <Acordeon />
          </div>
        </div>
      </section>
      <section className="s3-responsive">
        <div className="s3-faq">
          <div className="faq-title">
            <h2>Preguntas frecuentes</h2>
          </div>

          <div className="s3-img">
            <img src={image8} alt="faq" />
          </div>

          <div className="faq">
            <Acordeon />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Homepage;
