import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import "../styles/acordeon.css";
import { Player, PosterImage, BigPlayButton } from "video-react";
import image1 from '../assets/images/amico.png'
import basic from "../assets/images/plan_basico.png";





const Tutos = () => {
    return (
        <section className="tutos">
            {/*<div className="tutos-intro"><h1 className="intro-h1">Aqui encontrara toda la informacion que pueda ayudarle</h1></div>*/}
            <div className="tutos-container">
                <div className="tutos-left">
                    <a href="#1">
                        <h2>¿Como me conecto al CRM?</h2>
                    </a>
                    <a href="#2">
                        <h2>¿ Como cambiar el nombre a mi dominio dentro del CRM?</h2>
                    </a>
                    <a href="#3">
                        <h2>¿ Como cambiar los datos del CRM?</h2>
                    </a>
                    <a href="#4">
                        <h2>¿ Como uso el CRM de la manera adecuada?</h2>
                    </a>
                    <a href="#1">
                        <h2>¿ Como me registro correctamente?</h2>
                    </a>
                </div>
                <div className="tutos-right">
                    <Accordion flush alwaysOpen activeKey={["0", "1", "2", "3"]} >
                        <Accordion.Item eventKey="0"  >
                            <Accordion.Header id="1">¿Como me conecto al CRM ?</Accordion.Header>
                            <Accordion.Body>
                                <h2 className="video-des">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima mollitia dolore deleniti nesciunt debitis nostrum maiores placeat voluptas. Adipisci voluptas illum laudantium odio dolorem, inventore veniam totam neque non est.
                                    Officia magni omnis perspiciatis voluptatibus fuga ducimus obcaecati similique facere, nobis voluptate optio repellat alias dignissimos eveniet iste temporibus cupiditate quos cum dolor? Molestiae consequatur, placeat quia ab quisquam error.</h2>
                                <Player
                                    playsInline
                                    poster={basic}
                                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                            </Accordion.Body>
                        </Accordion.Item>
                        <div className="line-acordeon"></div>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header id="2">¿ Como cambiar el nombre a mi dominio ?</Accordion.Header>
                            <Accordion.Body>
                            <h2 className="video-des">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima mollitia dolore deleniti nesciunt debitis nostrum maiores placeat voluptas. Adipisci voluptas illum laudantium odio dolorem, inventore veniam totam neque non est.
                                    Officia magni omnis perspiciatis voluptatibus fuga ducimus obcaecati similique facere, nobis voluptate optio repellat alias dignissimos eveniet iste temporibus cupiditate quos cum dolor? Molestiae consequatur, placeat quia ab quisquam error.</h2>
                                <Player
                                    playsInline
                                    poster={basic}
                                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                            </Accordion.Body>
                        </Accordion.Item>
                        <div className="line-acordeon"></div>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header id="3">¿ Como cambiar los datos del CRM ?</Accordion.Header>
                            <Accordion.Body>
                            <h2 className="video-des">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima mollitia dolore deleniti nesciunt debitis nostrum maiores placeat voluptas. Adipisci voluptas illum laudantium odio dolorem, inventore veniam totam neque non est.
                                    Officia magni omnis perspiciatis voluptatibus fuga ducimus obcaecati similique facere, nobis voluptate optio repellat alias dignissimos eveniet iste temporibus cupiditate quos cum dolor? Molestiae consequatur, placeat quia ab quisquam error.</h2>
                                <Player
                                    playsInline
                                    poster={basic}
                                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                            </Accordion.Body>
                        </Accordion.Item>
                        <div className="line-acordeon"></div>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header id="4">¿ Como uso el CRM de la manera adecuada?</Accordion.Header>
                            <Accordion.Body>
                            <h2 className="video-des">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima mollitia dolore deleniti nesciunt debitis nostrum maiores placeat voluptas. Adipisci voluptas illum laudantium odio dolorem, inventore veniam totam neque non est.
                                    Officia magni omnis perspiciatis voluptatibus fuga ducimus obcaecati similique facere, nobis voluptate optio repellat alias dignissimos eveniet iste temporibus cupiditate quos cum dolor? Molestiae consequatur, placeat quia ab quisquam error.</h2>
                                <Player
                                    playsInline
                                    poster={basic}
                                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                                >
                                    <BigPlayButton position="center" />
                                </Player>
                            </Accordion.Body>
                        </Accordion.Item>
                        <div className="line-acordeon"></div>

                    </Accordion>
                </div>

            </div>
        </section>
    )

}

export default Tutos;