import React, { Component, useEffect, useState } from "react";
import Sidebar from '../../components/sidebar';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import "../../styles/admin-contactos.css";
import Adminsidebar from "../../components/admin-sidebar";


function Adminfisicas() {

    const [fisicas, setFisicas] = useState([]);
    const history = useHistory()



    useEffect(() => {
        async function fisicas() {
            const res = await axios.get('/api/fisicas/index');
            if (res.data.status === 200) {
                setFisicas(res.data.fisicas)
            }
        }
        fisicas();
    }, [])



    const deletePost = async (e, id) => {
        const deletedPost = e.currentTarget;
        deletedPost.innerText = "Deleting";
        const res = await axios.delete(`/api/admin/delete/fisica/${id}`);
        if (res.data.status === 200) {
            deletedPost.closest('tr').remove();
        }
        history.push('/admin/fisicas')
    }


    return (

        <>
        <Adminsidebar/>
            <section className="tables">
                <Link to="/admin/fisicas/create"><button type="button" id="create" class="btn btn-primary">Crear</button></Link>

                <table id="admin-table" className="table table-hover table-light">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Dni</th>
                            <th scope="col">Empresa</th>
                            <th scope="col">Usuarios</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Cuando</th>
                            <th scope="col">Ver</th>
                            <th scope="col">Editar</th>
                            <th scope="col">Borrar</th>

                        </tr>
                    </thead>
                    <tbody>
                        {fisicas.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.Nombre}</td>
                                    <td>{item.DNI}</td>
                                    <td>{item.Tipo_Empresa}</td>
                                    <td>{item.Numero_Usuarios}</td>
                                    <td>{item.Telefono_Movil}</td>
                                    <td>{moment(item.created_at).fromNow()}</td>
                                    <td>
                                        <Link to={`/admin/fisicas/show/${item.id}`} id="create" className="btn btn-success btn-sm">Ver</Link>
                                    </td>
                                    <td>
                                    <Link to={`/admin/fisicas/edit/${item.id}`} id='create' className="btn btn-info btn-sm approved">Edit</Link>
                                    </td>
                                    <td>
                                        <button type='button' id="create" onClick={(e) => deletePost(e, item.id)} className='btn btn-danger btn-sm'>Borrar</button>
                                    </td>
                                </tr>)
                        })
                        }


                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Adminfisicas;