import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar';
import logo from '../../assets/logo/logo.png'
import Sidebar from '../../components/sidebar';
import Footer from '../../components/footer';
import profile from "../../assets/icons/user.png";
import "../../styles/profile.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const ProfileFisica = () => {

    const [profile, setProfile] = useState([])

    const [users, setUsers] = useState([]);

    useEffect(() => {
      async function users() {
        const res = await axios.get("/api/current/user");
        if (res.data.status === 200) {
          setUsers(res.data.users);
        }
      }
      users();
    },[])

    useEffect(() => {
        async function test() {

            const res = await axios.get(`/api/physical/user`);
            if (res.data.status === 200) {
                setProfile({
                    id: res.data.users.id,
                    Nombre: res.data.users.Nombre,
                    Dni: res.data.users.DNI,
                    Telefono_Fijo: res.data.users.Telefono_Fijo,
                    Telefono_Movil: res.data.users.Telefono_Movil,
                    Email: res.data.users.Email,
                    Tipo_Empresa: res.data.users.Tipo_Empresa,
                    Numero_Usuarios: res.data.users.Numero_Usuarios,
                    Pais: res.data.users.Pais,
                    Provincia: res.data.users.Provincia,
                    Domicilio: res.data.users.Domicilio,
                    Codigo_Postal: res.data.users.Codigo_Postal,
                    Ciudad: res.data.users.Ciudad,
                    Subdominio: res.data.users.Subdominio,
                    Forma_Pago: res.data.users.Forma_Pago,
                    Nombre_Tarjeta: res.data.users.Nombre_Tarjeta,
                    Numero_Tarjeta: res.data.users.Numero_Tarjeta,
                    CVV_Tarjeta: res.data.users.Cvv_Tarjeta,
                    Caducidad_Suscripcion: res.data.users.Caducidad_Suscripcion,
                })
            }
        }
        test()
    }, [])

    return (
        <>
            <div className="sidebar-background">
                <Sidebar />
            </div>
            <div className='container-home'>
                <div>
                    <img className='logo' src={logo} alt="logo" />
                </div>
                <Navbar />
            </div>

            <section className="profile">
                <div className="left-profile">
                    <div className="user-img">
                    {users.file ? (
              <img
                src={
                  process.env.REACT_APP_PUBLIC_URL + `/storage/${users.file}`
                }
                alt="user-foto"
              />
            ) : (
              <img src={profile} alt="user-foto" />
            )}                    </div>

                    <div className="user-clients">
                        <h2>Tipo De Empresa</h2>
                        <h3>{profile.Tipo_Empresa}</h3>
                    </div>
                    <div className="user-clients">
                        <h2>Numero De Clientes</h2>
                        <h3>{profile.Numero_Usuarios}</h3>
                    </div>
                    <div className="user-domain">
                        <h2>Dominio</h2>
                        <h3>{profile.Subdominio}</h3>
                    </div>

                    <div className="user-edit">
                        <td>
                            <Link to={`/user/physical/edit/${profile.id}`} id='create' className="btn btn-info btn-sm approved">Editar</Link>
                        </td>
                    </div>
                   

                 
                </div>
                <div className="right-profile">
                    <div className="profile-container">

                        <div className="profile-box">
                            <div className="profile-title">
                                <h2>Detalles Administrador</h2>
                            </div>

                            <div className="profile-details">
                                <h2>Nombre</h2>
                                <h3>{profile.Nombre}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Dni</h2>
                                <h3>{profile.Dni}</h3>
                            </div>


                     
                            <div className="profile-details">
                                <h2>Telefono Movil</h2>
                                <h3>{profile.Telefono_Movil}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Email</h2>
                                <h3>{profile.Email}</h3>
                            </div>
                        </div>

                        <div className="profile-box">
                            <div className="profile-title">
                                <h2>Detalles Domicilio</h2>
                            </div>
                            <div className="profile-details">
                                <h2>Pais</h2>
                                <h3>{profile.Pais}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Provincia</h2>
                                <h3>{profile.Provincia}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Domicilio</h2>
                                <h3>{profile.Domicilio}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Ciudad</h2>
                                <h3>{profile.Ciudad}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Codigo Postal</h2>
                                <h3>{profile.Codigo_Postal}</h3>
                            </div>
                        </div>

                        {/* <div className="profile-box">
                            <div className="profile-title">
                                <h2>Detalles De Pago</h2>
                            </div>
                            <div className="profile-details">
                                <h2>Forma De Pago</h2>
                                <h3>{profile.Forma_Pago}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Nombre Tarjeta</h2>
                                <h3>{profile.Nombre_Tarjeta}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Numero Tarjeta</h2>
                                <h3>{profile.Numero_Tarjeta}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>CVV Tarjeta</h2>
                                <h3>{profile.CVV_Tarjeta}</h3>
                            </div>
                            <div className="profile-details">
                                <h2>Caducidad Suscripcion</h2>
                                <h3>{profile.Caducidad_Suscripcion}</h3>
                            </div>
                        </div> */}

                    </div>
                    <div className="profile-container">
                       
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )

}

export default ProfileFisica;