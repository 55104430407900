import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';
import cros from '../../assets/icons/croses.png';




function EditUser() {

    const { id } = useParams();
    const user_id = id;
    const [imagedata, setImagedata] = useState("");
    const history = useHistory()

    const [changeInput, setChange] = useState({
        username: "",
        email: "",
        password: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setChange({ ...changeInput, [e.target.name]: e.target.value });
    }




    const handleImage = (file) => {
        setImagedata(file[0]);
    };



    // const handleOption = (option) => {
    //     setOption('');
    // };

    // const handleImage = (files) => {
    //     setImagedata(files);
    // };

    // for (let i = 0; i < imagedata.length; i++)
    //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)


    useEffect(() => {
        async function test() {

            const res = await axios.get(`/api/edit/user/${user_id}`);
            if (res.data.status === 200) {
                setChange({
                    username: res.data.users.username,
                    email: res.data.users.email,
                    password: res.data.users.password,
                })
            }
        }
        test()
    }, [])

    const Update = async (e) => {
        e.preventDefault();

        const res = await axios.put(`api/update/user/${user_id}`, changeInput);
        if (res.data.status === 200) {

            swal({
                title: "Bien Hecho !",
                text: "Has editado tu perfil !",
                icon: "success",
                button: "Continuar...",
            });
            history.push("/admin/usuarios");



        }


    }

    return (
        <>
            <section className="form-creates">
                <Link to="/admin/usuarios">
                    <img src={cros} alt="" id="croses-ad" />
                </Link>
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={Update} className="form-house-create" id="create-admin-form-usuario">
                        <div className="form-lefts" id="form-lefts-admin">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Nombre Usuario</h2>
                                    <input type="text" placeholder="Usuario" name='username' onChange={handleInput} value={changeInput.username} />

                                </div>
                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="text" placeholder="Correo Electronico" name='email' onChange={handleInput} value={changeInput.email} />

                                </div>
                            </div>

                            <div className="superficies" id="pass-admin">
                                <div className="superficie" id="pass-admin">
                                    <h2>Contraseña</h2>

                                    <input type="password" placeholder="Cambia la Contraseña" name='password' onChange={handleInput} value={changeInput.password} />

                                </div>

                            </div>

                            <input type="file" name='file' id='image' onChange={e => handleImage(e.target.files)} />




                            <input type="submit" className="send-contact" id="admin-create-usuario-button" value="Añadir" />


                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default EditUser;