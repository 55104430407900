import React from 'react';

const Pago = () => {


    return(
        <>
        <h1>Pago aquí
            
        </h1>
        </>
    )
}

export default Pago;