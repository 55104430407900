import React from "react";
import { Link, useHistory } from "react-router-dom";
import logout from "../assets/icons/shutdown.png";
import Users from "../assets/icons/contacts.png";
import Juridicas from "../assets/icons/law.png";
import Fisicas from "../assets/icons/man.png";
import Contactos from "../assets/icons/profile.png";
import Mensajes from "../assets/icons/message.png";
import Logo from "../assets/logo/icon.ico";
import "../styles/admin-sidebar.css";

const Adminsidebar = () => {
  const history = useHistory();
  const logoutSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("auth_name");
    localStorage.removeItem("auth_token");
    history.push("/login");
  };

  return (
    <>
      <nav className="sidebars" id="side-active">
        <div className="side-logo">
          <img src={Logo} />
        </div>
        <div className="side-icons">
          <Link to="/admin/usuarios" className="linkto">
            <div className="side-icon">
              <img src={Users} alt="" className="side-img" />

              <h2 className="side-txt">Usuarios</h2>
            </div>
          </Link>
          <Link to="/admin/juridicas" className="linkto">
            <div className="side-icon">
              <img src={Juridicas} alt="x" className="side-img" />

              <h2 className="side-txt">Juridicas</h2>
            </div>
          </Link>
          <Link to="/admin/fisicas" className="linkto">
            <div className="side-icon">
              <img src={Fisicas} alt="" className="side-img" />

              <h2 className="side-txt">Fisicas</h2>
            </div>
          </Link>
          <Link to="/admin/contactos" className="linkto">
            <div className="side-icon">
              <img src={Contactos} alt="" className="side-img" />

              <h2 className="side-txt">Contactos</h2>
            </div>
          </Link>
          <Link to="/admin/mensajes" className="linkto">
            <div className="side-icon">
              <img src={Mensajes} alt="" className="side-img" />

              <h2 className="side-txt">Mensajes</h2>
            </div>
          </Link>
        </div>

        <div className="side-off">
          <a href="#" onClick={logoutSubmit}>
            <img src={logout} alt="yo" className="logout-ico" />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Adminsidebar;
