import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import '../styles/resetpass.css';

const ResetPassword = () => {

    const history = useHistory();


    const [registerInput, setRegister] = useState({
        email: "",
        error_list: [],
    });

    const handleInput = (e) => {
        setRegister({ ...registerInput, [e.target.name]: e.target.value });
    }


    const resetPassword = async (e) => {


        e.preventDefault();

        const data = new FormData();

        data.append("email", registerInput.email);


        await axios.post(process.env.REACT_APP_PUBLIC_URL+'/api/reset/password', data, { headers: { Accept: 'application/json' } }).then(res => {
            console.log(res);
            console.log(res.data);

            if (res.status === 200) {
                swal({
                    title: "¡Contraseña reseteada!",
                    text: 'Revisa tu email',
                    button: "Continuar",
                });
                history.push('/')
                console.log(res);

                setRegister({
                    email: '',

                });
            }

        });

    }

    return (
        <>
            <section className="reset-container">
                <div className="email-reset">
                    <form onSubmit={resetPassword}>
                        <h2>Ingresa tu dirección de correo electrónico</h2>
                        <input className="email" type="email" onChange={handleInput} value={registerInput.email} name="email" id="" placeholder="Email" />
                        <input className="submit" type="submit" value="Enviar" />
                    </form>
                </div>
            </section>
        </>
    )
}

export default ResetPassword;