import React, { Component, useEffect, useState } from "react";
import Sidebar from '../../components/sidebar';
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import "../../styles/admin-contactos.css";
import Adminsidebar from "../../components/admin-sidebar";


function Adminusuarios() {

    const [users, setUsers] = useState([]);
    const history = useHistory()



    useEffect(() => {
        async function users() {
            const res = await axios.get('/api/users/index');
            if (res.data.status === 200) {
                setUsers(res.data.users)
            }
        }
        users();
    }, [])



    const deletePost = async (e, id) => {
        const deletedPost = e.currentTarget;
        deletedPost.innerText = "Deleting";
        const res = await axios.delete(`api/delete/user/${id}`);
        if (res.data.status === 200) {
            deletedPost.closest('tr').remove();
        }
        history.push('/admin/usuarios')
    }

    return (

        <>
            <Adminsidebar />
            <section className="tables">
                <Link to="/admin/usuarios/create"><button type="button" id="create" class="btn btn-primary">Crear</button></Link>

                <table id="admin-table" className="table table-hover table-light">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Foto</th>
                            <th scope="col">Registrado</th>
                            <th scope="col">Editar</th>
                            <th scope="col">Borrar</th>

                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.file}</td>
                                    <td>{moment(item.created_at).fromNow()}</td>
                                    <td>
                                        <Link to={`/admin/usuarios/edit/${item.id}`} id='create' className="btn btn-info btn-sm approved">Edit</Link>
                                    </td>
                                    <td>
                                        <button type='button' id="create" onClick={(e) => deletePost(e, item.id)} className='btn btn-danger btn-sm'>Borrar</button>
                                    </td>
                                </tr>)
                        })
                        }


                    </tbody>
                </table>
            </section>
        </>
    )
}

export default Adminusuarios;