import {React, useLayoutEffect} from "react"
import Navbar from "../components/navbar";
import logo from "../assets/logo/logo.png";
import elena from "../assets/images/elena.jpg";
import david from "../assets/images/david.jpg";
import { Link } from "react-router-dom";
import "../styles/about.css";
import reunion from "../assets/images/andrea.jpg";
import about from "../assets/images/about-amico.png";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import Whatsapp from "../components/whatsapp";

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Sidebar />
      <Whatsapp />

      <section className="s1">
        <header>
          <div className="container-home">
            <div>
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Navbar />
          </div>
          <main>
          <div className="container-svg">
            <svg viewBox="0 0 450 250" preserveAspectRatio="xMinYMin meet">
              <path
                d="M0,200 C150,300 350,0 500,50 L500,00 L0,0 Z"
                style={{ stroke: "none", fill: "#00477d" }}
              ></path>
            </svg>
          </div>
          <div className="container-svg-responsive">
            <svg viewBox="0 0 320 339" preserveAspectRatio="xMidYMid meet">
              <g
                transform="translate(0.000000,339.000000) scale(0.100000,-0.100000)"
                fill="#000000"
                stroke="none"
              >
                <path
                  d="M0 1705 l0 -1685 28 -7 c15 -4 90 -7 167 -7 215 0 376 40 600 149
233 113 431 261 670 500 241 241 446 512 605 801 131 238 317 498 497 695 166
182 327 317 529 444 l99 63 3 366 2 366 -1600 0 -1600 0 0 -1685z"
                  style={{ stroke: "none", fill: "#00477d" }}
                />
              </g>
            </svg>
          </div>
            <div className="left" id="left-pad">
              <h1>¿Quiénes somos?</h1>
              <h2 id="about-h2">
                Somos una empresa formada por profesionales del sector del
                seguro con más de 20 años de experiencia
              </h2>
              <Link to={"/Contact"}>
                <h4>Contáctanos</h4>
              </Link>
            </div>
            <div className="right" id="right-pad">
              <img src={about} alt="" />
            </div>
          </main>
        </header>
      </section>
      <section className="s2-about">
        <div className="s2-about-title">
          {/*<img src={reunion} alt="reunion" />*/}

          <h2>
            WeDIBO nace de la necesidad y las carencias del control sobre
            nuestros propios datos y de los de nuestros gestores, además está
            creado por y para profesionales del sector seguros
            independientemente de la compañía o especialidad.
          </h2>
          {/*<Link>
            <h3>Contáctanos</h3>
          </Link>*/}
        </div>
        <div className="s2-about-mission">
          {/* <h3 className="about-mission">Nuestra mision</h3> */}
          <div className="s2-about-container">
            <div className="about-mission-logo">
              <img src={logo} alt="" />
            </div>
            <div className="about-leaders">
              <div className="about-leader">
                <img className="face" src={elena} alt="elena" />
                <h2>Elena Martínez</h2>
                <h4>CEO y Co-fundadora</h4>
              </div>
              <div className="about-leader">
                <img className="face" src={david} alt="david" />
                <h2>David Ramírez</h2>
                <h4>CTO y Co-fundador</h4>
              </div>
            </div>
            <h2></h2>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
