import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import "../../styles/register-juridica.css";
import cros from "../../assets/icons/croses.png";

function ShowMensajes() {
  const { id } = useParams();
  const user_id = id;
  const [changeInput, setChange] = useState({
    Nombre: "",
    Apellido: "",
    Telefono: "",
    Email: "",
    Mensaje: "",
  });

  const handleInput = (e) => {
    setChange({ ...changeInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    async function test() {
      const res = await axios.get(`/api/admin/show/mensaje/${user_id}`);
      if (res.data.status === 200) {
        setChange({
          Nombre: res.data.mensajes.Nombre,
          Apellido: res.data.mensajes.Apellido,
          Telefono: res.data.mensajes.Telefono,
          Email: res.data.mensajes.Email,
          Mensaje: res.data.mensajes.Mensaje,
        });
      }
    }
    test();
  }, []);

  return (
    <>
      <section className="form-creates">
        <Link to="/admin/juridicas">
          <img src={cros} alt="" id="croses-ad" />
        </Link>
        <div className="contact-forms" id="create-admin">
          <form className="form-house-create" id="create-admin-form">
            <div className="form-lefts" id="left-mensaje">
              <div className="superficies">
                <div className="superficie">
                  <h2>Nombre</h2>
                  <input
                    type="text"
                    name="Nombre"
                    placeholder=""
                    disabled
                    id="profile-input"
                    onChange={handleInput}
                    value={changeInput.Nombre}
                  />
                </div>
                <div className="superficie">
                  <h2>Apellido</h2>
                  <input
                    type="text"
                    name="Apellido"
                    disabled
                    id="profile-input"
                    onChange={handleInput}
                    value={changeInput.Apellido}
                  />
                </div>
              </div>
              <div className="superficies">
                <div className="superficie">
                  <h2>Email</h2>
                  <input
                    type="text"
                    name="Email"
                    disabled
                    id="profile-input"
                    placeholder="Su Domicilio "
                    onChange={handleInput}
                    value={changeInput.Email}
                  />
                </div>
                <div className="superficie">
                  <h2>Telefono</h2>
                  <input
                    type="text"
                    name="Telefono"
                    disabled
                    id="profile-input"
                    placeholder="Tipo Empresa"
                    onChange={handleInput}
                    value={changeInput.Telefono}
                  />
                </div>
              </div>
              <div className="superficies">
                <div className="superficie">
                  <h2>Mensaje</h2>
                  <input
                    type="text"
                    name="Mensaje"
                    disabled
                    id="profile-inputs"
                    placeholder="Nombre Del Administrador"
                    onChange={handleInput}
                    value={changeInput.Mensaje}
                  />
                </div>
                <div className="superficie">
                 
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default ShowMensajes;
