import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import swal from "sweetalert";
import '../../styles/register-juridica.css';
import cros from '../../assets/icons/croses.png';



function ShowJuridicas() {
    const { id } = useParams();
    const user_id = id;
    const history = useHistory()
    const [changeInput, setChange] = useState({
        Cif: "",
        Nombre_Fiscal: "",
        Nombre_Administrador: "",
        Dni: "",
        Domicilio_Fiscal: "",
        Telefono_Fijo: "",
        Telefono_Movil: "",
        Email: "",
        Tipo_Empresa: "",
        Numero_Usuarios: "",
        Pais: "",
        Provincia: "",
        Domicilio: "",
        Codigo_Postal: "",
        Ciudad: "",
        Subdominio: "",
        Forma_Pago: "",
        Nombre_Tarjeta: "",
        Numero_Tarjeta: "",
        CVV_Tarjeta: "",
        Caducidad_Suscripcion: "",
        error_list: [],
    });




    const handleInput = (e) => {
        setChange({ ...changeInput, [e.target.name]: e.target.value });
    }

    // const handleOption = (option) => {
    //     setOption('');
    // };

    // const handleImage = (files) => {
    //     setImagedata(files);
    // };

    // for (let i = 0; i < imagedata.length; i++)
    //     data.append(`imagenes[${i}]`, imagedata[i], imagedata[i].name)

    useEffect(() => {
        async function test() {

            const res = await axios.get(`/api/admin/edit/legal/user/${user_id}`);
            if (res.data.status === 200) {
                setChange({
                    id: res.data.users.id,
                    Cif: res.data.users.CIF,
                    Nombre_Fiscal: res.data.users.Nombre_Fiscal,
                    Nombre_Administrador: res.data.users.Nombre_Administrador,
                    Dni: res.data.users.DNI,
                    Domicilio_Fiscal: res.data.users.Domicilio_Fiscal,
                    Telefono_Fijo: res.data.users.Telefono_Fijo,
                    Telefono_Movil: res.data.users.Telefono_Movil,
                    Email: res.data.users.Email,
                    Tipo_Empresa: res.data.users.Tipo_Empresa,
                    Numero_Usuarios: res.data.users.Numero_Usuarios,
                    Pais: res.data.users.Pais,
                    Provincia: res.data.users.Provincia,
                    Domicilio: res.data.users.Domicilio,
                    Codigo_Postal: res.data.users.Codigo_Postal,
                    Ciudad: res.data.users.Ciudad,
                    Subdominio: res.data.users.Subdominio,
                    Forma_Pago: res.data.users.Forma_Pago,
                    Nombre_Tarjeta: res.data.users.Nombre_Tarjeta,
                    Numero_Tarjeta: res.data.users.Numero_Tarjeta,
                    CVV_Tarjeta: res.data.users.Cvv_Tarjeta,
                    Caducidad_Suscripcion: res.data.users.Caducidad_Suscripcion,
                })
            }
        }
        test()
    }, [])

    const Update = async (e) => {
        e.preventDefault();

        const res = await axios.put(`api/update/legal/user/${user_id}`, changeInput);
        if (res.data.status === 200) {

            swal({
                title: "Bien Hecho !",
                text: "Has editado tu perfil !",
                icon: "success",
                button: "Continuar...",
            });
            history.push("/profile/juridica");



        }
    }


    return (
        <>
            <section className="form-creates">
            <Link to="/admin/juridicas">
                    <img src={cros} alt="" id="croses-ad" />
                </Link>
                <div className="contact-forms" id="create-admin">
                    <form onSubmit={Update} className="form-house-create" id="create-admin-form">
                        <div className="form-lefts">
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>CIF</h2>
                                    <input type="text" name="Cif" placeholder="" disabled id='profile-input' onChange={handleInput} value={changeInput.Cif} />

                                </div>
                                <div className="superficie">
                                    <h2>Nombre Fiscal</h2>
                                    <input type="text" name="Nombre_Fiscal" disabled id='profile-input' onChange={handleInput} value={changeInput.Nombre_Fiscal} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Domicilio Fiscal</h2>
                                    <input type="text" name="Domicilio_Fiscal" disabled id='profile-input' placeholder="Su Domicilio " onChange={handleInput} value={changeInput.Domicilio_Fiscal} />
                                </div>
                                <div className="superficie">
                                    <h2>Tipo De Empresa</h2>
                                    <input type="text" name="Tipo_Empresa" disabled id='profile-input' placeholder="Tipo Empresa" onChange={handleInput} value={changeInput.Tipo_Empresa} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Nombre Administrador</h2>
                                    <input type="text" name="Nombre_Administrador" disabled id='profile-input' placeholder="Nombre Del Administrador" onChange={handleInput} value={changeInput.Nombre_Administrador} />
                                </div>
                                <div className="superficie">
                                    <h2>DNI</h2>
                                    <input type="text" name="Dni" id='profile-input' disabled placeholder="Su DNI" onChange={handleInput} value={changeInput.Dni} />
                                </div>
                            </div>
                            <div className="superficies">
                                <div className="superficie">
                                    <h2>Telefono Fijo</h2>
                                    <input type="text" name="Telefono_Fijo" disabled id='profile-input' placeholder="Su Fijo" onChange={handleInput} value={changeInput.Telefono_Fijo} />
                                </div>
                                <div className="superficie">
                                    <h2>Telefono Movil</h2>
                                    <input type="text" name="Telefono_Movil" disabled id='profile-input' placeholder="Su Movil" onChange={handleInput} value={changeInput.Telefono_Movil} />
                                </div>
                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Email</h2>
                                    <input type="email" name="Email" disabled id='profile-input' placeholder="Email" onChange={handleInput} value={changeInput.Email} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Numero De Clientes</h2>
                                    <input type="text" name="Numero_Usuarios" disabled id='profile-input-center' placeholder="" onChange={handleInput} value={changeInput.Numero_Usuarios} />
                                </div>

                            </div>






                        </div>
                        <div className="form-lefts">
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Pais</h2>
                                    <input type="text" name="Pais" disabled id='profile-input' placeholder="Su Email" onChange={handleInput} value={changeInput.Pais} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Provincia</h2>
                                    <input type="text" name="Provincia" disabled placeholder="Provincia" id='profile-input' onChange={handleInput} value={changeInput.Provincia} />
                                </div>

                            </div>
                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Domicilio</h2>
                                    <input type="text" name="Domicilio" disabled id='profile-input' placeholder="Domicilio" onChange={handleInput} value={changeInput.Domicilio} />
                                </div>
                                <div className="superficie">
                                    <h2 id='precio-ad'>Ciudad</h2>
                                    <input type="text" name="Ciudad" disabled placeholder="Ciudad" id='profile-input' onChange={handleInput} value={changeInput.Ciudad} />
                                </div>

                            </div>

                            <div className="superficies">

                                <div className="superficie">
                                    <h2>Codigo Postal</h2>
                                    <input type="text" name="Codigo_Postal" disabled id='profile-input' placeholder="18001" onChange={handleInput} value={changeInput.Codigo_Postal} />
                                </div>

                                <div className="superficie">
                                    <h2 id='precio-ad'>Subdominio Deseado</h2>
                                    <input type="text" name="Subdominio" disabled id='profile-input-center' placeholder="SI/NO" onChange={handleInput} value={changeInput.Subdominio} />
                                </div>


                            </div>




                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default ShowJuridicas;